<template>
  <div id="NewsManage" class="section">
    <div class="columns is-multiline">
      <div class="column is-10-desktop is-10-tablet is-12-mobile">
        <h1 class="title">
          NEWS MANAGER
        </h1>
      </div>
      <div class="column is-12-mobile">
        <b-button
          expanded
          type="is-primary"
          icon-left="plus"
          @click.prevent="navigateToNewsDetailPage"
        >
          Add News
        </b-button>
      </div>
    </div>

    <div class="container">
      <news-table />
    </div>
  </div>
</template>

<script>
import NewsTable from "@/components/tables/NewsTable";

export default {
  name: "NewsManage",
  components: {
    NewsTable
  },
  methods: {
    navigateToNewsDetailPage: function() {
      this.$router.push({ name: "news-detail" });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#NewsManage {
  text-align: left;
}
</style>
