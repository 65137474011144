<template>
  <div id="NewsTable">
    <b-table
      v-if="news"
      striped
      hoverable
      :data="news"
      @click="navigateToNewsDetail"
      style="cursor: pointer;"
    >
      <template slot-scope="props">
        <b-table-column field="image" label="Image">
          <figure class="image is-128x128">
            <img class="cover-center" :src="props.row.cover_image" />
          </figure>
        </b-table-column>

        <b-table-column field="title" label="Title">
          <div class="columns is-multiline">
            <div v-if="props.row.header.en" class="column is-12">
              {{ props.row.header.en }}
            </div>
          </div>
        </b-table-column>

        <b-table-column field="subtitle" label="Sub Title">
          <div class="columns is-multiline">
            <div class="column is-12">
              {{ props.row.short_description.en }}
            </div>
          </div>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import NewsApi from "@/apis/NewsApi";

export default {
  name: "NewsTable",
  data() {
    return {
      news: null
    };
  },
  filters: {
    dateformatter: function(value) {
      if (!value) return "";
      return Helpers.dateFormatter(value);
    }
  },
  methods: {
    getNewsList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApi.requestNewsList();
      loadingComponent.close();
      if (response && response.length > 0) {
        this.news = response;
      }
    },
    navigateToNewsDetail: function(selectedNews) {
      this.$router.push({
        name: "news-detail",
        params: { news: selectedNews }
      });
    }
  },
  mounted() {
    this.getNewsList();
  }
};
</script>

<style scoped>
.cover-center {
  object-position: center;
  object-fit: cover;
  height: 128px;
}
</style>
